import React, { Component } from 'react';

class Footer extends Component {
  render() {

    if(this.props.data){
      var copyright = this.props.data.copyright;
      var allright = this.props.data.allright;
      var website = this.props.data.website;
      var networks= this.props.data.social.map(function(network){
        return <li key={network.name}><a href={network.url}><i className={network.className}></i></a></li>
      })
    }

    return (
      <footer>

         <div className="row">
            <div className="twelve columns">
               <ul className="social-links">
                  {networks}
               </ul>

               <ul className="copyright">
                  <li>&copy; {copyright}</li>
                  <li><a title="Styleshout" href={website}>Entailment Inc</a></li>
                  <li>{allright}</li>
               </ul>

            </div>
            <div id="go-top"><a className="smoothscroll" title="Back to Top" href="#home"><i className="icon-up-open"></i></a></div>
         </div>
      </footer>
    );
  }
}

export default Footer;
