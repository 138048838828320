import React, { Component } from 'react';

class About extends Component {
  render() {

    if(this.props.data){
      var name = this.props.data.name;
      // var profilepic= "images/"+this.props.data.image;
      // var profilepic2= "images/"+this.props.data.image2;
      var bio = this.props.data.bio;
      var bio1 = this.props.data.bio1;
      var bio2 = this.props.data.bio2;
      var street = this.props.data.address.street;
      var city = this.props.data.address.city;
      var state = this.props.data.address.state;
      var zip = this.props.data.address.zip;
      var phone= this.props.data.phone;
      var email = this.props.data.email;
    }

    return (
      <section id="about">
      <div className="row">

         <div className="nine columns main-col">
            <h2>About Us</h2>

            <p>
               {bio} <br />
               {bio1} <br />
               {bio2}
            </p>
            
            <div>
              
            </div>

            <div className="row">
               <div className="columns contact-details">
                  <h2>Contact Details</h2>
                  <p className="address">
      						   <span>{name}</span><br />
                       {street ?
        						        <span>{street}<br/>
        						         {city} {state}, {zip}
                           </span>
                              :
                            null
                        }
        						   <span><br/>{phone}</span><br />
                     <span>{email}</span>
					        </p>
               </div>

            </div>
         </div>
      </div>

   </section>
    );
  }
}

export default About;
