import React, { Component } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import $ from 'jquery';

function onChange(value) {
   console.log("Captcha value:", value);
   if(value != null){
   submitRecaptcha(value);
   $('form#contactForm button.submit').show();
   }else{
      $('form#contactForm button.submit').hide();
   }
 }

 function submitRecaptcha(token){
   var data = {secret: '6LfYJbIZAAAAAKVxD3R1e0zkD8iSYWBu7njh6VEG', response:token};
   $.ajax({

      type: "POST",
      url: "https://www.google.com/recaptcha/api/siteverify",
      data: data,
      contentType: "application/x-www-form-urlencoded",
      dataType: 'json',
      crossDomain:true,
      success: function(msg) {

       console.log(msg);

      },
     error: function(msg){

       console.log(msg);
     }

   });
}

class Contact extends Component {
  render() {

    if(this.props.data){
      var name = this.props.data.name;
      var street = this.props.data.address.street;
      var city = this.props.data.address.city;
      var state = this.props.data.address.state;
      var zip = this.props.data.address.zip;
      var phone= this.props.data.phone;
      var email = this.props.data.email;
      var message = this.props.data.contactmessage;
    }

    return (
      <section id="contact">

         <div className="row section-head">

            <div className="two columns header-col">

               <h1><span>Get In Touch.</span></h1>

            </div>

            <div className="ten columns">

                  <p className="lead">{message}</p>

            </div>

         </div>

         <div className="row">
            <div className="eight columns">

               <form action="" method="post" id="contactForm" name="contactForm">
					<fieldset>

                  <div>
						   <label htmlFor="contactName">Name <span className="required">*</span></label>
						   <input type="text" defaultValue="" size="35" id="contactName" name="contactName" onChange={this.handleChange}/>
                  </div>

                  <div>
						   <label htmlFor="contactEmail">Email <span className="required">*</span></label>
						   <input type="text" defaultValue="" size="35" id="contactEmail" name="contactEmail" onChange={this.handleChange}/>
                  </div>

                  <div>
						   <label htmlFor="contactSubject">Subject</label>
						   <input type="text" defaultValue="" size="35" id="contactSubject" name="contactSubject" onChange={this.handleChange}/>
                  </div>

                  <div>
                     <label htmlFor="contactMessage">Message <span className="required">*</span></label>
                     <textarea cols="50" rows="15" id="contactMessage" name="contactMessage"></textarea>
                  </div>

                  <div className="recaptch-style">
                     <ReCAPTCHA
                        sitekey="6LfYJbIZAAAAAE7KDvzK84ekvNK1iGU6bA4xIAnz"
                        onChange={onChange}
                     />
                  </div>

                  <div>
                     <button className="submit">Submit</button>
                     <span id="image-loader">
                        <img alt="" src="images/loader.gif" />
                     </span>
                  </div>
					</fieldset>
				   </form>

           <div id="message-warning"> Error occured</div>
				   <div id="message-success">
                  <i className="fa fa-check"></i>Your message was sent, thank you!<br />
				   </div>
           </div>


            <aside className="four columns footer-widgets">
               <div className="widget widget_contact">

					   <h4>Contact Details</h4>
					   <p className="address">
               <span>{name}</span><br />
                   {street ?
                        <span>{street}<br/>
                         {city} {state}, {zip}
                       </span>
                          :
                        null
                    }
                   <span><br/>{phone}</span><br />
                 <span>{email}</span>
					   </p>
				   </div>
            </aside>
      </div>
   </section>
    );
  }
}

export default Contact;
